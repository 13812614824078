export const LOAD_USER_CALCULS = 'LOAD_USER_CALCULS';
export const GET_USER_CALCULS_SUCCESS = 'GET_USER_CALCULS_SUCCESS';
export const GET_USER_CALCULS_FAILED = 'GET_USER_CALCULS_FAILED';
export const CHANGE_CALCUL_SORT = 'CHANGE_CALCUL_SORT';
export const CHANGE_CALCUL_PAGE = 'CHANGE_CALCUL_PAGE';
export const GENERATE_REPORT = 'GENERATE_REPORT';
export const GENERATE_REPORT_FAILED = 'GENERATE_REPORT_FAILED';
export const DELETE_CALCUL = 'DELETE_CALCUL';
export const DELETE_CALCUL_SUCCESS = 'DELETE_CALCUL_SUCCESS';
export const DELETE_CALCUL_FAILED = 'DELETE_CALCUL_FAILED';
export const DUPLICATE_CALCUL = 'DUPLICATE_CALCUL';
export const DUPLICATE_CALCUL_SUCCESS = 'DUPLICATE_CALCUL_SUCCESS';
export const GET_CALCUL_SUCCESS = 'GET_CALCUL_SUCCESS';
export const LOAD_CALCUL = 'LOAD_CALCUL';
export const LOAD_CALCUL_SUCCESS = 'LOAD_CALCUL_SUCCESS';
export const LOAD_CALCUL_FAILED = 'LOAD_CALCUL_FAILED';