import {call, put, all, select, takeLatest} from "redux-saga/effects";
import {
    createConfiguratorCall,
    deleteConfiguratorCall,
    getConfiguratorsCall, getFilteredConfiguratorsCall,
    updateConfiguratorCall,
    sendFileToUploader
} from "../../../APIClient";
import {parseResponse} from "../../../utils/http";
import {
    CONFIGURATOR_FORM_DELETE,
    CONFIGURATOR_FORM_DELETE_FAILED,
    CONFIGURATOR_FORM_DELETE_SUCCESS,
    CONFIGURATOR_FORM_FAILED,
    CONFIGURATOR_FORM_SEND,
    CONFIGURATOR_FORM_SUCCESS,
    CONFIGURATOR_FORM_UPDATE,
    CONFIGURATOR_FORM_UPDATE_FAILED,
    CONFIGURATOR_FORM_UPDATE_SUCCESS,
    GET_CONFIGURATORS_FAILED,
    GET_CONFIGURATORS_SEND,
    GET_CONFIGURATORS_SUCCESS, GET_LOCAL_CONFIGURATOR_FAILED,
    GET_LOCAL_CONFIGURATOR_SUCCESS
} from "../constants";
import {formatFilePayload, formatPostConfiguratorPayload} from "../../../utils/transformer";
import {toast} from "react-toastify";
import {GET_LOCAL_CONFIGURATOR} from "../../../common/constants/action-types";
import {getLocalConfiguration} from "../actions";
import {push} from "redux-first-history";


function* getConfigurators () {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const data = yield call(getConfiguratorsCall, token);
        yield call(parseResponse, data, GET_CONFIGURATORS_SUCCESS);
    } catch (error) {
        yield put({type: GET_CONFIGURATORS_FAILED, error})
    }
}

function* postConfigurators (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        let imageUri = '';
        let secondaryImageUri = '';
        let documentationAboutImage = '';
        let documentationGuideFile = '';
        let documentationMethodologyFile = '';

        if (action.values && action.values.file && action.values.file.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.file));
            imageUri = image.url;
        }
        if (action.values && action.values.secondaryLogo && action.values.secondaryLogo.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.secondaryLogo));
            secondaryImageUri = image.url;
        }
        if (action.values && action.values.aboutImage && action.values.aboutImage.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.aboutImage));
            documentationAboutImage = image.url;
        }

        if (action.values && action.values.guideFile && action.values.guideFile.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.guideFile));
            documentationGuideFile = image.url;
        }

        if (action.values && action.values.methodologyFile && action.values.methodologyFile.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.methodologyFile));
            documentationMethodologyFile = image.url;
        }

        const data = yield call(createConfiguratorCall, token, formatPostConfiguratorPayload(action.values, imageUri, secondaryImageUri, documentationAboutImage, documentationGuideFile, documentationMethodologyFile));
        yield call(parseResponse, data, CONFIGURATOR_FORM_SUCCESS);
        yield put(getLocalConfiguration());
        yield put(push('/configurators'));

    } catch (error) {
        yield put({type: CONFIGURATOR_FORM_FAILED, error})
    }
}


function* patchConfigurator (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        let imageUri = '';
        let secondaryImageUri = '';
        let documentationAboutImage = '';
        let documentationGuideFile = '';
        let documentationMethodologyFile = '';
        let documentationFdesMereElementsFiles = [];
        let documentationFdesMereElementsPictures = [];

        if (action.values && action.values.file && action.values.file.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.file));
            imageUri = image.url;
        }
        if (action.values && action.values.secondaryLogo && action.values.secondaryLogo.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.secondaryLogo));
            secondaryImageUri = image.url;
        }
        if (action.values && action.values.aboutImage && action.values.aboutImage.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.aboutImage));
            documentationAboutImage = image.url;
        }

        if (action.values && action.values.guideFile && action.values.guideFile.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.guideFile));
            documentationGuideFile = image.url;
        }

        if (action.values && action.values.methodologyFile && action.values.methodologyFile.name) {
            const image = yield call(sendFileToUploader, token, formatFilePayload(action.values.methodologyFile));
            documentationMethodologyFile = image.url;
        }

        if (action.values && action.values.documentation && action.values.documentation.fdesMeres && action.values.documentation.fdesMeres.elements) {
            documentationFdesMereElementsFiles = yield all(action.values.documentation.fdesMeres.elements.filter(x => x.fileToSend && x.fileToSend.name).map(element => call(sendFileToUploader, token, formatFilePayload(element.fileToSend))));
            documentationFdesMereElementsPictures = yield all(action.values.documentation.fdesMeres.elements.filter(x => x.imageToSend && x.imageToSend.name).map(element => call(sendFileToUploader, token, formatFilePayload(element.imageToSend))))
        }


        const formattedConfigurator = formatPostConfiguratorPayload(action.values, imageUri, secondaryImageUri, documentationAboutImage, documentationGuideFile, documentationMethodologyFile, documentationFdesMereElementsFiles, documentationFdesMereElementsPictures);
        const data = yield call(updateConfiguratorCall, token, formattedConfigurator, formattedConfigurator.id);
        yield call(parseResponse, data, CONFIGURATOR_FORM_UPDATE_SUCCESS);
        yield put(getLocalConfiguration());
        yield put(push('/configurators'));
        yield put(toast.success("Modification enregistrée"));


    } catch (error) {
        yield put({type: CONFIGURATOR_FORM_UPDATE_FAILED, error})
    }
}

function* deleteConfigurator (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);

        yield call(deleteConfiguratorCall, token, action.configuratorId);
        yield call(parseResponse, action.configuratorId, CONFIGURATOR_FORM_DELETE_SUCCESS);

    } catch (error) {
        yield put({type: CONFIGURATOR_FORM_DELETE_FAILED, error})
    }
}



function* getLocalConfigurator () {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const data = yield call(getFilteredConfiguratorsCall, token);
        if (data && data.total > 0) {
            yield call(parseResponse, data.data[0], GET_LOCAL_CONFIGURATOR_SUCCESS);
        }
    } catch (error) {
        yield put({type: GET_LOCAL_CONFIGURATOR_FAILED, error})
    }
}

export const configuratorSagas = [
    takeLatest(GET_CONFIGURATORS_SEND, getConfigurators),
    takeLatest(CONFIGURATOR_FORM_SEND, postConfigurators),
    takeLatest(CONFIGURATOR_FORM_UPDATE, patchConfigurator),
    takeLatest(CONFIGURATOR_FORM_DELETE, deleteConfigurator),
    takeLatest(GET_LOCAL_CONFIGURATOR, getLocalConfigurator),
];
