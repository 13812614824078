import toExponential from 'to-exponential';

export function groupBy (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

export function formatToExponentielNumber (number) {
    if (!number || number === '') {
        return "0";
    }
    const numberString = number.toString().replace(',', '.').toLowerCase();
    const numberStringSplitted = numberString.split("e");
    let splitted = [];
    if (numberStringSplitted && numberStringSplitted.length > 1) {
        splitted = numberStringSplitted;
    } else {
        let exponentialNumber = toExponential(numberString);
        splitted = exponentialNumber.split('e');
    }

    return {number: parseFloat(splitted[0]).toFixed(2).replace('.', ','), exposant: splitted[1]};
}

export function checkNumberFormat (number) {
    if (!number || number === '') {
        return "0";
    }

    const numberString = number.toString().replace(',', '.').toLowerCase();
    const numberStringSplitted = numberString.split(".");

    if (
        numberStringSplitted[0] !== undefined &&
        (
            (+numberString >= 0.1 && +numberString < 1000) ||
            (+numberString <= -0.1 && +numberString > -1000)
        )
    ) {
        const sliceNumber = +numberStringSplitted[0] === 0 ? 3 : 2;
        // check if first value is 0 in order to keep it on decimals
        const prependZero = (numberStringSplitted[1] && numberStringSplitted[1][0] && (numberStringSplitted[1][0] === '0' || numberStringSplitted[1][0] === 0)) || false;
        let decimals = numberStringSplitted[1] && numberStringSplitted[1] !== "" ?
            Math.round(+numberStringSplitted[1].slice(0, sliceNumber))
            : undefined;
        if (decimals && prependZero) {
            decimals = `0${decimals}`;
        }

        return {number: decimals ? `${numberStringSplitted[0]},${decimals}` : numberStringSplitted[0], exposant: undefined};
    }

    return formatToExponentielNumber(number);
}
export function formatFdesParametersPayload (values, fdesId) {
    return {
        fdes: fdesId,
        parameters: values.filter(x => !x.isGeneralParameter).map(value => {
            let paramValue = (value.value && (value.value.label || value.value.label === '0' || value.value.label === 0)) ? value.value.label : "";
            if (value.type === 'percent' || value.type === 'percentslider') {
                // first lets convert to float
                if (paramValue) {
                    // and transform into 100 percent style
                    paramValue = (parseFloat(paramValue.toString().replace(',', '.'))*100).toFixed(4);
                    paramValue = parseFloat(paramValue);
                }// transform into a french style string
                paramValue = paramValue ? paramValue.toString().replace('.', ',').toLowerCase() : '';
                if (!paramValue.toString().includes('%') && paramValue !== "") {
                    // add the percent character
                    paramValue = `${paramValue}%`;
                }
            }
            return {
                paramId: value.paramId,
                paramValue: paramValue,
            }
        }
        )
    }
}
//@todo check if correctly implemented
export function formatGoogleFdesParametersPayload (values, fdesId) {
    return {
        fdes: fdesId,
        parameters: values.filter(x => !x.isGeneralParameter).map(value => {
            let paramValue = (value.value && (value.value.label || value.value.label === '0' || value.value.label === 0)) ? value.value.label : "";
            if (value.type === 'percent' || value.type === 'percentslider') {
                // first lets convert to float
                if (paramValue) {
                    // and transform into 100 percent style
                    paramValue = (parseFloat(paramValue.toString().replace(',', '.'))*100).toFixed(4);
                    paramValue = parseFloat(paramValue);
                }// transform into a french style string
                paramValue = paramValue ? paramValue.toString().replace('.', ',').toLowerCase() : '';
                if (!paramValue.toString().includes('%') && paramValue !== "") {
                    // add the percent character
                    paramValue = `${paramValue}%`;
                }
            }
            return {
                paramId: value.paramId,
                paramValue: paramValue,
                externalId: value.externalId,
                type: value.type,
            }
        }
        )
    }
}

export function formatFdesResultParametersPayload (values, fdesId, calculName, user, projectName, calculateId, results, additionalResults, settings, configuratorId) {

    const parameters = values.filter(x => x).filter(x => !x.isGeneralParameter).map(parameter => {
        return {
            paramId: parameter.paramId,
            paramValue: parameter.value.label
        }
    });
    const generalParameters = values.filter(x => x.isGeneralParameter).map(parameter => {
        return {
            paramId: parameter.paramId,
            paramValue: parameter.value.label
        }
    });

    return {
        fdes: fdesId,
        title: calculName,
        project: projectName,
        resultId: calculateId,
        organisation: configuratorId,
        configurator: configuratorId,
        user: user._id,
        results,
        additionalResults,
        settings: settings,
        parameters,
        generalParameters
    }
}

export function formatGenerateXmlPayload (userFdesId, reportType) {
    return {
        outputs: reportType ? [reportType] : ["xml", "pdf"],
        userFdes: userFdesId
    }
}

export function formatLoginPayload (values) {
    return {
        email: values.email || undefined,
        password: values.password || undefined,
        strategy: "local"
    }
}

export function formatPostConfiguratorPayload (values, imageUri, secondaryImageUri, documentationAboutImage, documentationGuideFile, documentationMethodologyFile, documentationFdesMereElementsFiles, documentationFdesMereElementsPictures) {
    return {
        id: values.id && values.id !== '' ? values.id : undefined,
        title: values.name,
        code: values.code,
        color: values.color,
        logo: imageUri || undefined,
        secondaryLogo: secondaryImageUri || undefined,
        secondaryLogoUrl: values.secondaryLogoUrl || undefined,
        domain: values.domain,
        email: {
            adminContactEmail: values.adminContactEmail
        },
        domainIsPublic: values?.domainIsPublic === "1" || false,
        theme: {
            colors: {
                primary: values && values.theme && values.theme.colors ? values.theme.colors.primary : undefined,
                secondary: values && values.theme && values.theme.colors ? values.theme.colors.secondary : undefined,
                primaryText: values && values.theme && values.theme.colors ? values.theme.colors.primaryText : undefined,
                secondaryText: values && values.theme && values.theme.colors ? values.theme.colors.secondaryText : undefined,
            },
            logoPosition: values && values.theme ? values.theme.logoPosition : undefined,
            secondaryLogoPosition: values && values.theme ? values.theme.secondaryLogoPosition : undefined,
            headerStyle: values && values.theme ? values.theme.headerStyle : undefined,
            menuStyle: values && values.theme ? values.theme.menuStyle : undefined,
        },
        security: {
            allowRegister: values?.security?.allowRegister === "1" || false,
            accessControl: {
                anonymousMode: values && values.security && values.security.accessControl && values.security.accessControl.anonymousMode ? values.security.accessControl.anonymousMode : undefined,
            }
        },
        content: {
            homepage: {
                title: values.content && values.content.homepage && values.content.homepage.title ? values.content.homepage.title : '',
                text: values.content && values.content.homepage && values.content.homepage.text ? values.content.homepage.text : '',
                video: values.content && values.content.homepage && values.content.homepage.video ? values.content.homepage.video : '',
                buttons: {
                    button_1: {
                        isActive: values.content && values.content.homepage && values.content.homepage.buttons && values.content.homepage.buttons.button_1 && values.content.homepage.buttons.button_1.isActive === '1' ? true : false,
                        label: values.content && values.content.homepage && values.content.homepage.buttons && values.content.homepage.buttons.button_1 && values.content.homepage.buttons.button_1.label ? values.content.homepage.buttons.button_1.label : 'En savoir plus',
                    },
                    button_2: {
                        isActive: values.content && values.content.homepage && values.content.homepage.buttons && values.content.homepage.buttons.button_2 && values.content.homepage.buttons.button_2.isActive === '1' ? true : false,
                        label: values.content && values.content.homepage && values.content.homepage.buttons && values.content.homepage.buttons.button_2 && values.content.homepage.buttons.button_2.label ? values.content.homepage.buttons.button_2.label : 'Configurer une FDES',
                    },
                }
            },
            productFamilies: {
                title: values.content && values.content.productFamilies && values.content.productFamilies ? values.content.productFamilies.title : '',
                text: values.content && values.content.productFamilies && values.content.productFamilies ? values.content.productFamilies.text : '',
            },
            cgu: values.content && values.content.cgu && values.content.cgu ? values.content.cgu : ''
        },
        documentation: {
            about: {
                title: values.documentation && values.documentation.about ? values.documentation.about.title : '',
                description: values.documentation && values.documentation.about ? values.documentation.about.description : '',
                image: documentationAboutImage || (values.documentation && values.documentation.about ? values.documentation.about.image : ''),
                menu: {
                    _id: values.documentation && values.documentation.about && values.documentation.about.menu && values.documentation.about.menu._id ? values.documentation.about.menu._id : false,
                    isActive: values.documentation && values.documentation.about && values.documentation.about.menu && values.documentation.about.menu.isActive === "1" ? true : false,
                    label: values.documentation && values.documentation.about && values.documentation.about.menu && values.documentation.about.menu.label ? values.documentation.about.menu.label : "À propos",
                }
            },
            fdesMeres: {
                title: values.documentation && values.documentation.fdesMeres ? values.documentation.fdesMeres.title : '',
                description: values.documentation && values.documentation.fdesMeres ? values.documentation.fdesMeres.description : '',
                menu: {
                    _id: values.documentation && values.documentation.fdesMeres && values.documentation.fdesMeres.menu && values.documentation.fdesMeres.menu._id ? values.documentation.fdesMeres.menu._id : false,
                    isActive: values.documentation && values.documentation.fdesMeres && values.documentation.fdesMeres.menu && values.documentation.fdesMeres.menu.isActive === "1" ? true : false,
                    label: values.documentation && values.documentation.fdesMeres && values.documentation.fdesMeres.menu && values.documentation.fdesMeres.menu.label ? values.documentation.fdesMeres.menu.label : "FDES mères",
                },
                elements: values.documentation && values.documentation.fdesMeres ? values.documentation.fdesMeres.elements.map((element, index) => {
                    let elementFile = element.file || '';
                    let elementPicture = element.image || '';

                    if (element.fileToSend) {
                        elementFile = documentationFdesMereElementsFiles[0].url;
                        documentationFdesMereElementsFiles.shift();
                    }
                    if (element.imageToSend) {
                        elementPicture = documentationFdesMereElementsPictures[0].url;
                        documentationFdesMereElementsPictures.shift();
                    }

                    return {
                        title: element.title,
                        description: element.description,
                        file: elementFile,
                        image: elementPicture,
                        order: element.order,
                    }
                }
                ) : []
            },
            videoTutorial: {
                title: values.documentation && values.documentation.videoTutorial ? values.documentation.videoTutorial.title : '',
                description: values.documentation && values.documentation.videoTutorial ? values.documentation.videoTutorial.description : '',
                video: values.documentation && values.documentation.videoTutorial ? values.documentation.videoTutorial.video : '',
                menu: {
                    _id: values.documentation && values.documentation.videoTutorial && values.documentation.videoTutorial.menu && values.documentation.videoTutorial.menu._id ? values.documentation.videoTutorial.menu._id : false,
                    isActive: values.documentation && values.documentation.videoTutorial && values.documentation.videoTutorial.menu && values.documentation.videoTutorial.menu.isActive === "1" ? true : false,
                    label: values.documentation && values.documentation.videoTutorial && values.documentation.videoTutorial.menu && values.documentation.videoTutorial.menu.label ? values.documentation.videoTutorial.menu.label : "Tutoriels",
                },
            },
            guide: {
                title: values.documentation && values.documentation.guide ? values.documentation.guide.title : '',
                description: values.documentation && values.documentation.guide ? values.documentation.guide.description : '',
                file: documentationGuideFile || (values.documentation && values.documentation.guide ? values.documentation.guide.file : ''),
                label: values.documentation && values.documentation.guide ? values.documentation.guide.label : '',
                menu: {
                    _id: values.documentation && values.documentation.guide && values.documentation.guide.menu && values.documentation.guide.menu._id ? values.documentation.guide.menu._id : false,
                    isActive: values.documentation && values.documentation.guide && values.documentation.guide.menu && values.documentation.guide.menu.isActive === "1" ? true : false,
                    label: values.documentation && values.documentation.guide && values.documentation.guide.menu && values.documentation.guide.menu.label ? values.documentation.guide.menu.label : "Guides",
                },
            },
            methodology: {
                title: values.documentation && values.documentation.methodology ? values.documentation.methodology.title : '',
                description: values.documentation && values.documentation.methodology ? values.documentation.methodology.description : '',
                file: documentationMethodologyFile || (values.documentation && values.documentation.methodology ? values.documentation.methodology.file : ''),
                label: values.documentation && values.documentation.methodology ? values.documentation.methodology.label : '',
                menu: {
                    _id: values.documentation && values.documentation.methodology && values.documentation.methodology.menu && values.documentation.methodology.menu._id ? values.documentation.methodology.menu._id : false,
                    isActive: values.documentation && values.documentation.methodology && values.documentation.methodology.menu && values.documentation.methodology.menu.isActive === "1" ? true : false,
                    label: values.documentation && values.documentation.methodology && values.documentation.methodology.menu && values.documentation.methodology.menu.label ? values.documentation.methodology.menu.label : "Méthodologies",
                },
            },
            faq: {
                elements: values.documentation && values.documentation.faq ? values.documentation.faq.elements.map((element, index) => {
                    return {
                        title: element.title,
                        text: element.text,
                        order: element.order,
                    }
                }) : []
            }
        },
        productFamilies: values.familyProducts || []
    }
}

export function formatPostGroupPayload (values) {
    return {
        users: values.includeUsers ? values.includeUsers.filter((item) => item).map(x => x._id) : undefined,
        active: true,
        title: values.title || undefined,
        configurator: values.configuratorId || undefined,
        productFamilies: values.productFamilies ? values.productFamilies.map(x => x) : undefined
    }
}

export function formatPostFamilyProductPayload (values, imageUri, guideFile, methodologyFile, documentationFdesMereElementsFiles) {
    return {
        id: values.id || undefined,
        name: values.name,
        label: values.label,
        shortLabel: values.shortLabel || undefined,
        description: values.description,
        image: imageUri || undefined,
        parent: values.parentId || undefined,
        content: {
            helpTextLabel: values.content && values.content.helpTextLabel && values.content.helpTextLabel ? values.content.helpTextLabel : 'Réaliser une FDES'
        },
        documentation: {
            guide: {
                file: guideFile || (values.documentation && values.documentation.guide && values.documentation.guide.file ? values.documentation.guide.file : ''),
                label: values.documentation && values.documentation.guide ? values.documentation.guide.label : '',
            },
            tutorial: {
                file: methodologyFile || (values.documentation && values.documentation.tutorial && values.documentation.tutorial.file ? values.documentation.tutorial.file : ''),
                label: values.documentation && values.documentation.tutorial ? values.documentation.tutorial.label : '',
            },
            videoTutorial: {
                title: values.documentation && values.documentation.videoTutorial ? values.documentation.videoTutorial.title : '',
                description: values.documentation && values.documentation.videoTutorial ? values.documentation.videoTutorial.description : '',
                video: values.documentation && values.documentation.videoTutorial ? values.documentation.videoTutorial.video : '',
            },
            fdesMeres: {
                elements: values.documentation && values.documentation.fdesMeres ? values.documentation.fdesMeres.elements.map((element) => {
                    let elementFile = element.file || '';

                    if (element.fileToSend) {
                        elementFile = documentationFdesMereElementsFiles[0].url;
                        documentationFdesMereElementsFiles.shift();
                    }
                    return {
                        title: element.title,
                        file: elementFile,
                        order: element.order,
                    }
                }
                ) : []
            },
        }
    }
}

export function formatPostUserPayload (values) {
    return {
        id: values.id && values.id !== '' ? values.id : undefined,
        email: values.email || undefined,
        password: values.password || undefined,
        permissions: values.permissions ? values.permissions : [],
        firstName: values.firstName || undefined,
        lastName: values.lastName || undefined,
        type: values.type || undefined,
        configurators: values.configurators || [],
        permissionRules: values.permissionRules ? values.permissionRules.map(x => x) : undefined,
    }
}

export function formatPatchUserPayload (values) {
    return {
        id: values.id && values.id !== '' ? values.id : undefined,
        password: values.password || undefined,
        permissions: values.permissions ? values.permissions : undefined,
        firstName: values.firstName || undefined,
        lastName: values.lastName || undefined,
        type: values.type || undefined,
        configurators: values.configurators || undefined,
        permissionRules: values.permissionRules ? values.permissionRules.map(x => x).filter(x => x.role !== "") : undefined,
    }
}

export function formatPatchPasswordUserPayload (values) {
    return {
        id: values.id && values.id !== '' ? values.id : undefined,
        password: values.password || undefined,
        type: values.type || undefined,
        firstName: values.firstName || undefined,
        lastName: values.lastName || undefined,
    }
}


export function formatPostFdes (values, iconUrl, paramUrl, calculUrl, methodologyNoteUri, fdesMereUri, guideUri) {
    return {
        title: values.name || '',
        description: values.description || '',
        changelog: values.changelog || '',
        icon: iconUrl || undefined,
        parametersCsv: paramUrl || undefined,
        resultCsv: calculUrl || undefined,
        methodologyNote: methodologyNoteUri || undefined,
        version: values.version || '',
        reportReference: values.reportReference || undefined,
        reportTemplateType: values.reportTemplateType || undefined,
        productFamily: values.familyProductId,
        parentEPDId: values.parentEPDId || undefined,
        parentEPDSerialId: values.parentEPDSerialId || undefined,
        compatible: values.compatible === "1" || false,
        standard: values.standard || "NF_EN_15804-A1",
        documentation: {
            fdesMere: {
                file: fdesMereUri || undefined,
                label: ""
            },
            guide: {
                file: guideUri || undefined,
                label: ""
            }
        },
        calculus: {
            googleDrive: {
                sheetId: values.googleDriveSheetId || ''
            }
        },
        settings: {
            fileNameStrategy: values && values.settings && values.settings.fileNameStrategy ? values.settings.fileNameStrategy : 'FAMILY+NAME'
        }
    }
}


export function formatPatchFdes (values, iconUrl) {
    return {
        title: values.name || '',
        description: values.description || '',
        changelog: values.changelog || '',
        reportReference: values.reportReference || undefined,
        reportTemplateType: values.reportTemplateType || undefined,
        reportReferenceUpdatedAt: values.oldReportReference !== values.reportReference ? new Date() : undefined,
        icon: iconUrl || undefined,
        version: values.version || '',
        parentEPDId: values.parentEPDId || undefined,
        parentEPDSerialId: values.parentEPDSerialId || undefined,
        compatible: values.compatible === "1" || false,
        standard: values.standard || "NF_EN_15804-A1",
        calculus: {
            googleDrive: {
                sheetId: values.googleDriveSheetId || ''
            }
        },
        settings: {
            fileNameStrategy: values && values.settings && values.settings.fileNameStrategy ? values.settings.fileNameStrategy : 'FAMILY+NAME'
        }
    }
}

export function formatPostRecoverPasswordPayload (values, localConfiguratorId) {
    return {
        action: "sendResetPwd",
        value: {email: values.email},
        notifierOptions: {
            configurator: localConfiguratorId
        }
    }
}

export function formatPostSaveRecoverPasswordPayload (values) {
    return {
        action: "resetPwdLong",
        value: {token: values.token, password: values.password}
    }
}

export function formatRegisterPayload (values, configuratorId) {
    return {
        "email": values.email,
        "password": values.password,
        "firstName": values.firstName,
        "lastName": values.lastName,
        "type": values.type,
        "configurators": [configuratorId]
    }
}

export function formatPostVerificationPayload (token) {
    return {
        action: "verifySignupLong",
        value: token
    }
}

export function formatPostResetVerificationPayload (email, localConfiguratorId) {
    return {
        action: "resendVerifySignup",
        value: {email},
        notifierOptions: {
            configurator: localConfiguratorId
        }
    }
}

export function formatFilePayload (file) {
    const formData = new FormData();
    formData.append('uri', file, file.name);

    return formData;
}

export function formatSendEmail (values) {
    return {
        username: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message
    }
}

export const userTypeKeyToLabel = {
    'fabricant-produit-construction': 'Fabricant de produits de construction',
    'organisation-professionnelle': 'Organisation professionnelle',
    'be-thermique-batiment': 'BE thermique bâtiment',
    'be-acv-batiment': 'BE ACV bâtiment',
    'be-acv-produit-construction': 'BE ACV produits de construction',
    'architecte': 'Architecte',
    'maitre-ouvrage-prive': 'Maitre d\'ouvrage privé',
    'maitre-ouvrage-public': 'Maitre d\'ouvrage public',
    'entreprise-batiment': 'Entreprise du bâtiment',
    'enseignant': 'Enseignant',
    'etudiant': 'Étudiant',
    other: 'Autre',
};

export const HEADER_CENTER = 'header-center';
export const HEADER_LEFT = 'header-left';
export const MENU_TOP = 'menu-top';
export const MENU_BOTTOM = 'menu-bottom';

export const THEME_LIGHT = 'light';
export const THEME_DARK = 'dark';
export const THEME_SEMI_LIGHT = 'semi-light';
export const THEME_SEMI_DARK = 'semi-dark';
export const THEME_DEFAULT = 'navbar-dark';

export const MENU_LIGHT = 'light';
export const MENU_DARK = 'dark';
export const MENU_DEFAULT = 'menu-dark';

export const ANONYMOUS_MODE_ALL = 'ALL';
export const ANONYMOUS_MODE_DISABLED = 'DISABLED';
export const ANONYMOUS_MODE_ONLY_HOMEPAGE = 'ONLY_HOMEPAGE';

export const logoPositionKeyToLabel = {
    [HEADER_CENTER]: 'Au centre du header',
    [HEADER_LEFT]: 'Dans le coin haut gauche',
    [MENU_TOP]: 'En haut de la navigation gauche',
    [MENU_BOTTOM]: 'En bas de la navigation gauche',

};
export const themeHeaderKeyToLabel = {
    [THEME_LIGHT]: 'Light',
    [THEME_DARK]: 'Dark',
    [THEME_SEMI_LIGHT]: 'Semi light',
    [THEME_SEMI_DARK]: 'Semi dark',

};
export const themeMenuKeyToLabel = {
    [THEME_LIGHT]: 'Light',
    [THEME_DARK]: 'Dark',

};

export const anonymousModeKeyToLabel = {
    [ANONYMOUS_MODE_ALL]: 'Toutes les pages sont accessibles (sauf Mes calculs)',
    [ANONYMOUS_MODE_ONLY_HOMEPAGE]: 'Seule la page d\'accueil est accessible',
    [ANONYMOUS_MODE_DISABLED]: 'Aucune page n\'est accessible',
};

export const fileNameStrategyKeyToLabel = {
    // eslint-disable-next-line
    ['FAMILY_LABEL+NAME']: 'Libellé complet famille + nom du calcul',
    // eslint-disable-next-line
    ['FAMILY_SHORT_LABEL+NAME']: 'Libellé court famille + nom calcul',
    // eslint-disable-next-line
    ['NAME']: 'Nom du calcul',
    // eslint-disable-next-line
    ['NAME+PROJECT']: 'Nom du calcul + nom du projet',
    // eslint-disable-next-line
    ['NAME+DATE']: 'Nom du calcul + Date du calcul',
};

export const fdesTemplateType = {
    'STATIC': 'Statique',
    'DYNAMIC': 'Dynamique',
};

export const flattenFamilyProducts = (routes) =>
    routes.reduce((acc, r) => {
        if (r.children && r.children.length) {
            acc = acc.concat(flattenFamilyProducts(r.children));
        }
        acc.push(r);

        return acc;
    }, []);

export const formatToCalculatedNumber = (number) => {
    number = number?.toString() || "0";
    return parseFloat(number.replace(/,/g, '.'));
};
