import {
    CHECK_LOGIN_FAILED,
    CHECK_LOGIN_SUCCESS,
    CLOSE_LOGIN,
    DISCONNECT_USER,
    LOGIN_ATTEMPT_NOT_VERIFIED, LOGIN_FORM_FAILED, LOGIN_FORM_SEND,
    LOGIN_FORM_SUCCESS, LOGIN_SAML,
    OPEN_LOGIN, RESENT_VALIDATION_SUCCESS, RESET_LOGIN_ATTEMPT
} from "../constants/action-types";
import produce from "immer";
import {
    GET_USERS_SUCCESS,
    UPDATE_ACCOUNT_SETTING_FORM_SUCCESS,
    USER_FORM_DELETE_SUCCESS
} from "../../modules/user/constants";

const initialState = {
    isAuthenticated: false,
    informations: {
        name: '',
        email: '',
        token: ''
    },
    loginAttempt: {
        email: '',
        isVerified: true
    },
    users: [],
    loginModalIsOpen: false,
    error: null,
};

const contextReducer = produce((draft = initialState, action) => {
    switch (action.type) {
        case DISCONNECT_USER:
            draft.isAuthenticated = false;
            draft.informations = {};
            break;
        case OPEN_LOGIN:
            draft.loginModalIsOpen = true;
            break;
        case CLOSE_LOGIN:
            draft.error = null;
            draft.loginModalIsOpen = false;
            break;
        case GET_USERS_SUCCESS:
            formatUsersResponse(draft, action.payload);
            break;
        case USER_FORM_DELETE_SUCCESS:
            removeUser(draft, action.payload);
            break;
        case UPDATE_ACCOUNT_SETTING_FORM_SUCCESS:
            storeUserInformations(draft, action.payload);
            break;
        case LOGIN_FORM_SEND:
            draft.error = null;
            break;
        case LOGIN_SAML:
            draft.error = null;
            break;
        case LOGIN_FORM_SUCCESS:
            const {accessToken, user} = action.payload;

            draft.informations = {...user, id: user._id, token: accessToken};
            draft.isAuthenticated = true;
            draft.error = null;
            break;
        case LOGIN_FORM_FAILED:
            draft.error = action.error;
            break;
        case LOGIN_ATTEMPT_NOT_VERIFIED:
            draft.loginAttempt.email = action.email;
            draft.loginAttempt.isVerified = false;
            break;
        case RESENT_VALIDATION_SUCCESS:
            draft.loginAttempt.email = '';
            draft.loginAttempt.isVerified = false;
            break;
        case RESET_LOGIN_ATTEMPT:
            if (draft.loginAttempt) {
                draft.loginAttempt.email = '';
                draft.loginAttempt.isVerified = true;
            }
            break;
        case CHECK_LOGIN_SUCCESS:
            draft.error = null;
            draft.isAuthenticated = true;
            draft.informations = {...action.payload, id: action.payload._id};
            break;
        case CHECK_LOGIN_FAILED:
            draft.isAuthenticated = false;
            draft.informations = {name: '', email: '', token: ''};
            break;
        default:
            return draft;
    }
});
function storeUserInformations (draft, payload) {
    const token = draft.informations.token;
    draft.informations = {...payload, id: payload._id, token};
}
function removeUser (draft, payload) {
    draft.users.splice(draft.users.findIndex(user => user.id === payload), 1);
}

function formatUsersResponse (draft, payload) {
    if (payload && payload.data && payload.data.length > 0) {
        draft.users = payload.data.map(user =>
        ({
            ...user,
            id: user._id
        })
        )
    }
}
export default contextReducer;
