import {
    OPEN_LOGIN,
    CLOSE_LOGIN,
    RESET_LOGIN_ATTEMPT,
    RESENT_VALIDATION,
    LOGIN_ATTEMPT_NOT_VERIFIED,
    LOGIN_FORM_SEND,
    DISCONNECT_USER,
    GET_LOCAL_CONFIGURATOR,
    LOGIN_REQUIRED,
    PERMISSION_REFUSED,
    SEND_RECOVER_PASSWORD_FORM,
    SAVE_RECOVER_PASSWORD_FORM,
    LOGIN_SAML,
    CHECK_LOGIN
} from "../constants/action-types";

export function resetLoginAttempt () {
    return {
        type: RESET_LOGIN_ATTEMPT
    };
}
export function resentValidation () {
    return {
        type: RESENT_VALIDATION
    };
}
export function loginAttemptNotVerified (email) {
    return {
        type: LOGIN_ATTEMPT_NOT_VERIFIED, email
    };
}
export function disconnectUser () {
    return {
        type: DISCONNECT_USER
    };
}
export function openLogin () {
    return {
        type: OPEN_LOGIN
    };
}
export function closeLogin () {
    return {
        type: CLOSE_LOGIN
    };
}
export function sendFormLogin (values) {
    return {
        type: LOGIN_FORM_SEND, values
    };
}
export function checkLogin () {
    return {
        type: CHECK_LOGIN
    }
}
export function loginSaml (values) {
    return {
        type: LOGIN_SAML, values
    }
}
export function getLocalConfigurator () {
    return {
        type: GET_LOCAL_CONFIGURATOR
    };
}
export function loginRequired () {
    return {
        type: LOGIN_REQUIRED
    };
}
export function permissionRefused () {
    return {
        type: PERMISSION_REFUSED
    };
}
export function sendRecoverPasswordForm (values) {
    return {
        type: SEND_RECOVER_PASSWORD_FORM, values
    };
}
export function saveRecoverPasswordForm (values) {
    return {
        type: SAVE_RECOVER_PASSWORD_FORM, values
    };
}
