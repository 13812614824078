import React, {useEffect, useState} from 'react';
import Select from "react-select";
import ReactTooltip from "react-tooltip";
import './SyntheticResult.scss';
import CollapsableCard from "../../../common/components/CollapsableCard";
import ThermoGaugeChart from "./ThermoGaugeChart";
import {DEFAULT_ACTIVE_COLOR, selectStyles} from "../../../common/constants/color";
import {AFFICHAGERSLT, ETAPESRS, INDICATEURRS} from '../constants/metadata';
import LabelChart from './LabelChart';

const SyntheticResult = ({steps, additionalResults, localConfigurator, indicators, onChangeSyntheticResultForm, indicatorValue, stepValue, result, measure, metadata}) => {
    const activeColor = localConfigurator && localConfigurator.theme && localConfigurator.theme.colors && localConfigurator.theme.colors.primaryText ? localConfigurator.theme.colors.primaryText : DEFAULT_ACTIVE_COLOR;


    const [indicateursRs, setIndicateursRs] = useState({})
    const [etapesRs, setEtapesRs] = useState({})
    const [affichageRslt, setAffichageRslt] = useState({})

    useEffect(() => {
        metadata && setIndicateursRs(metadata.find(e => e.paramId === INDICATEURRS))
        metadata && setEtapesRs(metadata.find(e => e.paramId === ETAPESRS))
        metadata && setAffichageRslt(metadata.find(e => e.paramId === AFFICHAGERSLT))
    }, [metadata])


    function displayRslt () {
        if (affichageRslt) {
            if (affichageRslt.defaultValue === 'label')
                return false
            return true
        } else
            return true
    }

    return (
        <CollapsableCard title="Résultats synthétiques">
            {(displayRslt())
                ?
                <div className="row">
                    <div className="col-7">
                        <fieldset className="form-group">
                            <label htmlFor="basicInput">{indicateursRs && (indicateursRs.name ? indicateursRs.name : 'Indicateur environnemental')} <i
                                className="feather icon-info ml-3"
                                data-tip={indicateursRs && (indicateursRs.helpText ? indicateursRs.helpText : "Sélectionnez l’indicateur environnemental pour lequel vous souhaitez afficher le résultat. Les indicateurs proposés sont ceux requis en France pour la réalisation de déclarations environnementales de produits et équipements de construction, et la réalisation d’analyses de cycle de vie de bâtiments.")}
                                data-for="indicators" /></label>
                            <Select
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                styles={selectStyles(activeColor)}
                                value={indicatorValue}
                                onChange={(selectedOption) => onChangeSyntheticResultForm('indicator', selectedOption)}
                                options={indicators && (indicators.map(indicator => {
                                    return {value: indicator.id, label: indicator.title}
                                }))}
                            />
                            <ReactTooltip
                                id="indicators"
                                place="top"
                                type="info"
                                className="basic-tooltip"
                            />
                        </fieldset>
                        <fieldset className="form-group">
                            <label htmlFor="basicInput">{etapesRs && (etapesRs.name ? etapesRs.name : 'Étape ou module du cycle de vie')} <i
                                className="feather icon-info ml-3"
                                data-tip={etapesRs && (etapesRs.helpText ? etapesRs.helpText : "Sélectionnez l’étape ou le module du cycle de vie pour lequel vous souhaitez afficher le résultat. Les étapes et modules proposés sont ceux requis en France pour la réalisation de déclarations environnementales de produits et équipements de construction, et la réalisation d’analyses de cycle de vie de bâtiments.")}
                                data-for="indicators" /></label>
                            <Select
                                menuPortalTarget={document.body}
                                menuPosition="fixed"
                                styles={selectStyles(activeColor)}
                                value={stepValue}
                                onChange={(selectedOption) => onChangeSyntheticResultForm('step', selectedOption)}
                                options={steps && (steps.map(step => {
                                    return {value: step.id, label: `${step.acronym} - ${step.title}`}
                                }))}
                            />
                            <ReactTooltip
                                id="indicators"
                                place="top"
                                type="info"
                                className="basic-tooltip"
                            />
                        </fieldset>
                    </div>
                    <div className="col-5">
                        {
                            result && result.value && result.measurements && <ThermoGaugeChart value={result.value} measure={measure} measurements={result.measurements} helpText={affichageRslt && (affichageRslt.helpText || null)} />
                        }
                    </div>
                </div>
                :
                <div className="row">
                    <div className="col-12">
                        {
                            additionalResults && additionalResults.find(x => x.name === "Note_textile") && <LabelChart value={additionalResults.find(x => x.name === "Note_textile").value} />
                        }
                    </div>
                </div>
            }
        </CollapsableCard>
    )
};

export default SyntheticResult;