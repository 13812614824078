import {connect} from 'react-redux';
import Layout from '../../layout';
import {checkLogin, getLocalConfigurator} from "../actions/user";
import {getFamilyProducts} from "../../modules/family-product/actions";

export default connect(
    (state) => ({
        location: state.router.location,
        localConfigurator: state.configurator.localConfigurator
    }),
    (dispatch) => ({
        getLocalConfigurator: () => {
            dispatch(getLocalConfigurator());
        },

        getFamilyProductsRequest: () => {
            dispatch(getFamilyProducts());
        },
        checkIfLogged: () => {
            dispatch(checkLogin())
        }
    })
)(Layout);