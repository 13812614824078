import {
    LOAD_USER_CALCULS,
    CHANGE_CALCUL_SORT,
    CHANGE_CALCUL_PAGE,
    GENERATE_REPORT,
    DELETE_CALCUL,
    DELETE_CALCUL_SUCCESS, DELETE_CALCUL_FAILED, DUPLICATE_CALCUL, LOAD_CALCUL
} from "../constants";

export function loadUserCalculs () {
    return {
        type: LOAD_USER_CALCULS
    };
}
export function getCalcul (calculId) {
    return {
        type: LOAD_CALCUL, calculId
    };
}
export function changeCalculSort (sort) {
    return {
        type: CHANGE_CALCUL_SORT, sort
    };
}
export function changeCalculPage (page) {
    return {
        type: CHANGE_CALCUL_PAGE, page
    };
}
export function generateReport (userFdesId, reportType) {
    return {
        type: GENERATE_REPORT, userFdesId, reportType
    };
}
export function deleteCalcul (calculId) {
    return {
        type: DELETE_CALCUL, calculId
    };
}
export function deleteCalculSuccess (data) {
    return {
        type: DELETE_CALCUL_SUCCESS, payload: data
    };
}
export function deleteCalculFailed () {
    return {
        type: DELETE_CALCUL_FAILED
    };
}

export function duplicateCalcul (calculId) {
    return {
        type: DUPLICATE_CALCUL, calculId
    }
}