import React, {useEffect, useState} from 'react';
import Fdes from "../components/Fdes";
import {withPermissions} from "../../../layout/Permissions";
import {PERMISSION_USER} from "../../user/constants/permissions";
import {useParams} from 'react-router-dom';

const FdesEditPage = (props) => {
    const {formIsLoading, familyProducts, loadFdesParameters, isEdited, saveFdesForm, loadReference, saveFdesResult, resultFormIsLoading,
        resultFormIsSaved, lastSavedUserFdesId, generateXml, calculs, updateCalcul, setLastSavedUserFdesId,
        cleanNameForm, saveFdesGoogleForm, xmlReportIsLoading, pdfReportIsLoading, onUpgradeCalcul, calculIsLoading, getFdesMetadata, metadata, getCalcul, calcul} = props
    const [familyProduct, setFamilyProduct] = useState({});

    const params = useParams()

    useEffect(() => {
        if (params && params.familyProductId) {
            const flatten = (routes) =>
                routes.reduce((acc, r) => {
                    if (r.children && r.children.length) {
                        acc = acc.concat(flatten(r.children));
                    }
                    acc.push(r);

                    return acc;
                }, []);
            setFamilyProduct(flatten(familyProducts).find(x => x._id === params.familyProductId))
        }

        if (params && params.calculId) {
            setLastSavedUserFdesId(params.calculId);
        }

    }, [params, familyProducts, calculs, setLastSavedUserFdesId]);

    return (
        <>
            <Fdes
                familyProduct={familyProduct}
                loadFdesParameters={loadFdesParameters}
                isEdited={isEdited}
                cleanNameForm={cleanNameForm}
                saveFdesForm={saveFdesForm}
                saveFdesGoogleForm={saveFdesGoogleForm}
                loadReference={loadReference}
                saveFdesResult={saveFdesResult}
                resultFormIsLoading={resultFormIsLoading}
                resultFormIsSaved={resultFormIsSaved}
                lastSavedUserFdesId={lastSavedUserFdesId}
                generateXml={generateXml}
                calcul={calcul}
                updateCalcul={updateCalcul}
                readOnly={(calcul && calcul.reports) || (calcul && calcul.lastModel && calcul.lastModel._id !== calcul.model._id)}
                xmlReportIsLoading={xmlReportIsLoading}
                pdfReportIsLoading={pdfReportIsLoading}
                onUpgradeCalcul={onUpgradeCalcul}
                calculIsLoading={calculIsLoading}
                formIsLoading={formIsLoading}
                getFdesMetadata={getFdesMetadata}
                metadata={metadata}
                id={params ? params.calculId : undefined}
                getCalcul={getCalcul}
            />
        </>
    )

};

export default withPermissions(FdesEditPage, PERMISSION_USER);
