import React, {useEffect} from 'react';
import CalculRaw from "./CalculRaw";
import './Calculs.scss';
import {
    SORT_CALCUL_CREATED_AT_ASC,
    SORT_CALCUL_CREATED_AT_DESC,
    SORT_CALCUL_FAMILY_PRODUCT_ASC,
    SORT_CALCUL_FAMILY_PRODUCT_DESC, SORT_CALCUL_INDICATOR_AT_ASC, SORT_CALCUL_INDICATOR_AT_DESC,
    SORT_CALCUL_MODIFIED_AT_ASC,
    SORT_CALCUL_MODIFIED_AT_DESC,
    SORT_CALCUL_NAME_ASC,
    SORT_CALCUL_NAME_DESC,
    SORT_CALCUL_PROJECT_NAME_ASC,
    SORT_CALCUL_PROJECT_NAME_DESC
} from "../../../common/constants/sort";
import Paginator from "../../../common/components/Paginator";
import Loader from "../../../common/components/Loader";

const Calculs = ({
    deleteCalcul,
    duplicateCalcul,
    loadUserCalculs,
    calculs,
    changeCalculSort,
    calculIdXmlReport,
    calculIdPdfReport,
    xmlReportIsLoading,
    pdfReportIsLoading,
    sort,
    total,
    currentPage,
    limit,
    onChangePage,
    generatePdf,
    generateXml,
    isLoading,
    aggregatedFamilyProducts,
    getFdesMetadataCalculRaw,
}) => {

    useEffect(() => {
        loadUserCalculs();
    }, [loadUserCalculs]);

    return (
        <div className="calculs">
            <div className="card">
                <div className="card-content">
                    <Loader isActive={isLoading} />
                    <div className="card-body calcul-table-size">
                        <div className="table-responsive d-block tableFixHead">
                            <table className="table table-striped table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th className="sortable" onClick={() => changeCalculSort(sort === SORT_CALCUL_NAME_ASC ? SORT_CALCUL_NAME_DESC : SORT_CALCUL_NAME_ASC)}>
                                            Nom du calcul
                                            <div className="sort-container">
                                                <span className={sort === SORT_CALCUL_NAME_ASC ? 'active' : sort === SORT_CALCUL_NAME_DESC ? 'disabled' : ''}><i className="fa fa-caret-up" /></span>
                                                <span className={sort === SORT_CALCUL_NAME_DESC ? 'active' : sort === SORT_CALCUL_NAME_ASC ? 'disabled' : ''}><i className="fa fa-caret-down" /></span>
                                            </div>
                                        </th>
                                        <th className="sortable" onClick={() => changeCalculSort(sort === SORT_CALCUL_PROJECT_NAME_ASC ? SORT_CALCUL_PROJECT_NAME_DESC : SORT_CALCUL_PROJECT_NAME_ASC)}>Nom du projet
                                            <div className="sort-container">
                                                <span className={sort === SORT_CALCUL_PROJECT_NAME_ASC ? 'active' : sort === SORT_CALCUL_PROJECT_NAME_DESC ? 'disabled' : ''}><i className="fa fa-caret-up" /></span>
                                                <span className={sort === SORT_CALCUL_PROJECT_NAME_DESC ? 'active' : sort === SORT_CALCUL_PROJECT_NAME_ASC ? 'disabled' : ''}><i className="fa fa-caret-down" /></span>
                                            </div></th>
                                        <th className="sortable" onClick={() => changeCalculSort(sort === SORT_CALCUL_FAMILY_PRODUCT_ASC ? SORT_CALCUL_FAMILY_PRODUCT_DESC : SORT_CALCUL_FAMILY_PRODUCT_ASC)}>Famille de produit
                                            <div className="sort-container">
                                                <span className={sort === SORT_CALCUL_FAMILY_PRODUCT_ASC ? 'active' : sort === SORT_CALCUL_FAMILY_PRODUCT_DESC ? 'disabled' : ''}><i className="fa fa-caret-up" /></span>
                                                <span className={sort === SORT_CALCUL_FAMILY_PRODUCT_DESC ? 'active' : sort === SORT_CALCUL_FAMILY_PRODUCT_ASC ? 'disabled' : ''}><i className="fa fa-caret-down" /></span>
                                            </div></th>
                                        <th>Version</th>
                                        <th className="sortable" onClick={() => changeCalculSort(sort === SORT_CALCUL_CREATED_AT_ASC ? SORT_CALCUL_CREATED_AT_DESC : SORT_CALCUL_CREATED_AT_ASC)}>Création
                                            <div className="sort-container">
                                                <span className={sort === SORT_CALCUL_CREATED_AT_ASC ? 'active' : sort === SORT_CALCUL_CREATED_AT_DESC ? 'disabled' : ''}><i className="fa fa-caret-up" /></span>
                                                <span className={sort === SORT_CALCUL_CREATED_AT_DESC ? 'active' : sort === SORT_CALCUL_CREATED_AT_ASC ? 'disabled' : ''}><i className="fa fa-caret-down" /></span>
                                            </div></th>
                                        <th className="sortable" onClick={() => changeCalculSort(sort === SORT_CALCUL_MODIFIED_AT_ASC ? SORT_CALCUL_MODIFIED_AT_DESC : SORT_CALCUL_MODIFIED_AT_ASC)}>Modification
                                            <div className="sort-container">
                                                <span className={sort === SORT_CALCUL_MODIFIED_AT_ASC ? 'active' : sort === SORT_CALCUL_MODIFIED_AT_DESC ? 'disabled' : ''}><i className="fa fa-caret-up" /></span>
                                                <span className={sort === SORT_CALCUL_MODIFIED_AT_DESC ? 'active' : sort === SORT_CALCUL_MODIFIED_AT_ASC ? 'disabled' : ''}><i className="fa fa-caret-down" /></span>
                                            </div></th>
                                        <th className="sortable" onClick={() => changeCalculSort(sort === SORT_CALCUL_INDICATOR_AT_ASC ? SORT_CALCUL_INDICATOR_AT_DESC : SORT_CALCUL_INDICATOR_AT_ASC)}>Résultat
                                            <div className="sort-container">
                                                <span className={sort === SORT_CALCUL_INDICATOR_AT_ASC ? 'active' : sort === SORT_CALCUL_INDICATOR_AT_DESC ? 'disabled' : ''}><i className="fa fa-caret-up" /></span>
                                                <span className={sort === SORT_CALCUL_INDICATOR_AT_DESC ? 'active' : sort === SORT_CALCUL_INDICATOR_AT_ASC ? 'disabled' : ''}><i className="fa fa-caret-down" /></span>
                                            </div>
                                        </th>
                                        <th>Export</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody className="">
                                    {calculs && calculs.length > 0 && (
                                        calculs.map(calcul =>
                                            <CalculRaw key={calcul._id}
                                                calculIdXmlReport={calculIdXmlReport}
                                                calculIdPdfReport={calculIdPdfReport}
                                                xmlReportIsLoading={xmlReportIsLoading}
                                                pdfReportIsLoading={pdfReportIsLoading}
                                                deleteCalcul={deleteCalcul}
                                                duplicateCalcul={duplicateCalcul}
                                                calcul={calcul}
                                                generatePdf={generatePdf}
                                                generateXml={generateXml}
                                                aggregatedFamilyProducts={aggregatedFamilyProducts}
                                                getFdesMetadataCalculRaw={getFdesMetadataCalculRaw}
                                            />)
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <Paginator total={total} currentPage={currentPage} limit={limit} onChangePage={onChangePage} className="mb-3" />
        </div>
    )

};

export default Calculs;
