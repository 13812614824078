import {call, put, select, takeEvery, takeLatest} from 'redux-saga/effects'
import {
    generateReportCall,
    getFdesParametersCall,
    getReferencesCall,
    postFdesResultCall,
    postFdesGoogleResultCall,
    saveFdesResultCall, updateCalculCall, getFdesMetaDataCall, postFdesUploadFileCall
} from "../../../APIClient";
import {parseResponse} from "../../../utils/http";
import {
    GET_FDES_PARAMETERS_SUCCESS,
    SAVE_FDES_FORM_FAILED,
    SAVE_FDES_FORM_SUCCESS,
    LOAD_FDES_PARAMETERS,
    SAVE_FDES_FORM,
    SAVE_FDES_FORM_GOOGLE,
    LOAD_REFERENCE_SUCCESS,
    LOAD_REFERENCE_FAILED,
    LOAD_REFERENCE,
    SAVE_FDES_RESULT,
    SAVE_FDES_RESULT_SUCCESS,
    SAVE_FDES_RESULT_FAILED,
    GENERATE_FDES_XML, UPDATE_CALCUL, UPDATE_CALCUL_SUCCESS, UPDATE_CALCUL_FAILED, GET_FDES_METADATA_SUCCESS, GET_FDES_METADATA, POST_FDES_FILE, POST_FDES_FILE_FAILED, GET_FDES_METADATA_CALCUL_RAW
} from "../constants";
import {changeFdesParameterForm, loadCalculData, saveCurrentFdesId} from "../actions";
import {
    formatFdesParametersPayload,
    formatGoogleFdesParametersPayload,
    formatFdesResultParametersPayload,
    formatGenerateXmlPayload,
    formatFilePayload,
} from "../../../utils/transformer";
import {toast} from "react-toastify";
import {GENERATE_REPORT, GENERATE_REPORT_FAILED, LOAD_USER_CALCULS} from "../../calcul/constants";
import {
    disablePdfReportLoader,
    disableReportsLoader, disableXmlReportLoader,
    enablePdfReportLoader,
    enableXmlReportLoader
} from "../../../common/actions/loader";
import {loadUserCalculs} from "../../calcul/actions";
import {push} from 'redux-first-history';

function* getReferences (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const data = yield call(getReferencesCall, token, action.standard);
        yield call(parseResponse, data, LOAD_REFERENCE_SUCCESS);

    } catch (error) {
        yield put({type: LOAD_REFERENCE_FAILED, error})
    }
}

function* getFdesParameters (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const data = yield call(getFdesParametersCall, token, action.fdesId);
        yield call(parseResponse, data, GET_FDES_PARAMETERS_SUCCESS);
        yield put(saveCurrentFdesId(action.fdesId));
        if (!action.calcul) {
            yield put({type: action.isRealTime ? SAVE_FDES_FORM_GOOGLE : SAVE_FDES_FORM})
        } else {
            yield put(loadCalculData(action.calcul));
        }
    } catch (error) {
        yield put({type: "GET_FDES_PARAMETERS_FAILED", error})
    }
}

function* getFdesMetaData (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const data = yield call(getFdesMetaDataCall, token, action.fdesId);
        yield call(parseResponse, data, GET_FDES_METADATA_SUCCESS);
    } catch (error) {
        yield put({type: "GET_FDES_PARAMETERS_FAILED", error})
    }
}

function* getFdesMetaDataCalculRaw (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const data = yield call(getFdesMetaDataCall, token, action.fdesId);
        data.code === '200' ? action.reject() : console.log(data);
    } catch (error) {
        action.reject();
        yield put({type: "GET_FDES_PARAMETERS_FAILED", error})
    }
}

function* postFdesResult () {
    const getToken = (state) => state.user.informations.token;
    const getFormFields = (state) => state.fdes.form.fields;
    const getCurrentFdesId = (state) => state.fdes.form.currentFdesId;

    try {
        const token = yield select(getToken);
        const fields = yield select(getFormFields);
        const currentFdesId = yield select(getCurrentFdesId);
        const data = yield call(postFdesResultCall, token, formatFdesParametersPayload(fields, currentFdesId));
        yield call(parseResponse, data, SAVE_FDES_FORM_SUCCESS);
    } catch (error) {
        yield put({type: SAVE_FDES_FORM_FAILED, error})
    }
}
function* postFdesGoogleResult () {
    const getToken = (state) => state.user.informations.token;
    const getFormFields = (state) => state.fdes.form.fields;
    const getCurrentFdesId = (state) => state.fdes.form.currentFdesId;

    try {
        const token = yield select(getToken);
        const fields = yield select(getFormFields);
        const currentFdesId = yield select(getCurrentFdesId);
        const data = yield call(postFdesGoogleResultCall, token, formatGoogleFdesParametersPayload(fields, currentFdesId));
        yield call(parseResponse, data, SAVE_FDES_FORM_SUCCESS);
    } catch (error) {
        yield put({type: SAVE_FDES_FORM_FAILED, error})
    }
}

function* postFdesUploadFile (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const data = yield call(postFdesUploadFileCall, token, formatFilePayload(action.file))
        const value = {
            value: data.url,
            label: data.url
        }
        yield put(changeFdesParameterForm(action.paramId, value))
    } catch (error) {
        console.log(error)
        yield put({type: POST_FDES_FILE_FAILED, error})
    }
}

function* saveFdesResult () {
    const getToken = (state) => state.user.informations.token;
    const getCalculName = (state) => state.fdes.calculName;
    const getProjectName = (state) => state.fdes.projectName;
    const getFormFields = (state) => state.fdes.form.fields;
    const getUser = (state) => state.user.informations;
    const getCurrentFdesId = (state) => state.fdes.form.currentFdesId;
    const getCalculateId = (state) => state.fdes.calculateId;
    const getResults = (state) => state.fdes.results;
    const getAdditionalResults = (state) => state.fdes.additionalResults;
    const getSettings = (state) => state.fdes.settings;
    const getLocalConfigurator = (state) => state.configurator.localConfigurator;

    try {
        const token = yield select(getToken);
        const fields = yield select(getFormFields);
        const calculName = yield select(getCalculName);
        const projectName = yield select(getProjectName);
        const currentFdesIs = yield select(getCurrentFdesId);
        const calculateId = yield select(getCalculateId);
        const user = yield select(getUser);
        const results = yield select(getResults);
        const additionalResults = yield select(getAdditionalResults);
        const settings = yield select(getSettings);
        const localConfigurator = yield select(getLocalConfigurator);

        if (!calculName || calculName === '') {
            yield call(toast.error, "Le nom de votre calcul doit être renseigné pour être enregistré.");
        } else {
            const data = yield call(saveFdesResultCall, token, formatFdesResultParametersPayload(fields, currentFdesIs, calculName, user, projectName, calculateId, results, additionalResults, settings, localConfigurator._id));
            yield call(parseResponse, data, SAVE_FDES_RESULT_SUCCESS);
            yield put(loadUserCalculs())
            yield call(toast.success, "Résultat enregistré");
        }
    } catch (error) {
        yield put({type: SAVE_FDES_RESULT_FAILED, error})
    }
}

function* patchCalcul (action) {
    const getToken = (state) => state.user.informations.token;
    const getCalculName = (state) => state.fdes.calculName;
    const getProjectName = (state) => state.fdes.projectName;
    const getFormFields = (state) => state.fdes.form.fields;
    const getUser = (state) => state.user.informations;
    const getCurrentFdesId = (state) => state.fdes.form.currentFdesId;
    const getResults = (state) => state.fdes.results;
    const getAdditionalResults = (state) => state.fdes.additionalResults;
    const getSettings = (state) => state.fdes.settings;
    const getLocalConfigurator = (state) => state.configurator.localConfigurator;

    try {
        const token = yield select(getToken);
        const fields = yield select(getFormFields);
        const calculName = yield select(getCalculName);
        const projectName = yield select(getProjectName);
        const currentFdesIs = yield select(getCurrentFdesId);
        const user = yield select(getUser);
        const results = yield select(getResults);
        const additionalResults = yield select(getAdditionalResults);
        const settings = yield select(getSettings);
        const localConfigurator = yield select(getLocalConfigurator);

        if (!calculName || calculName === '') {
            yield call(toast.error, "Le nom de votre calcul doit être renseigné pour être enregistré.");
        } else {
            const data = yield call(updateCalculCall, token, formatFdesResultParametersPayload(fields, currentFdesIs, calculName, user, projectName, action.calculId, results, additionalResults, settings, localConfigurator._id), action.calculId);
            yield call(parseResponse, data, UPDATE_CALCUL_SUCCESS);
            yield put(loadUserCalculs())
            yield call(toast.success, "Résultat enregistré");
        }
    } catch (error) {
        yield put({type: UPDATE_CALCUL_FAILED, error})
    }
}

function* postFdesXml (action) {
    const getToken = (state) => state.user.informations.token;
    const getLastSavedUserFdesId = (state) => state.fdes.resultForm.lastSavedUserFdesId;

    try {
        const token = yield select(getToken);
        const lastSavedUserFdesId = yield select(getLastSavedUserFdesId);

        yield put(enablePdfReportLoader(lastSavedUserFdesId))
        yield put(enableXmlReportLoader(lastSavedUserFdesId))

        yield call(generateReportCall, token, formatGenerateXmlPayload(lastSavedUserFdesId, "xml"));
        yield call(toast.success, `Export xml généré`);
        yield put(disableXmlReportLoader())
        yield put(loadUserCalculs())
        yield put(push(`/fdes/${action.familyProductId}/${lastSavedUserFdesId}`));


        yield call(generateReportCall, token, formatGenerateXmlPayload(lastSavedUserFdesId, "pdf"));
        yield call(toast.success, `Export pdf généré`);
        yield put(disablePdfReportLoader())

        yield put(loadUserCalculs())
        yield put(push(`/fdes/${action.familyProductId}/${lastSavedUserFdesId}`));
    } catch (error) {
        yield put({type: GENERATE_REPORT_FAILED, error})
    }
}

function* generateSingleReport (action) {
    const getToken = (state) => state.user.informations.token;

    try {
        const token = yield select(getToken);
        const reportType = action.reportType;
        const userFdesId = action.userFdesId;
        if (reportType === 'pdf') {
            yield put(enablePdfReportLoader(userFdesId))
        } else {
            yield put(enableXmlReportLoader(userFdesId))
        }

        const data = yield call(generateReportCall, token, formatGenerateXmlPayload(userFdesId, reportType));

        yield call(parseResponse, data, LOAD_USER_CALCULS);

        yield call(toast.success, `Export ${reportType} généré`);
        if (reportType === 'pdf') {
            yield put(disablePdfReportLoader())
        } else {
            yield put(disableXmlReportLoader())
        }
    } catch (error) {
        yield put(disableReportsLoader())
    }
}


export const fdesSagas = [
    takeLatest(POST_FDES_FILE, postFdesUploadFile),
    takeLatest(GET_FDES_METADATA, getFdesMetaData),
    takeLatest(GET_FDES_METADATA_CALCUL_RAW, getFdesMetaDataCalculRaw),
    takeLatest(LOAD_FDES_PARAMETERS, getFdesParameters),
    takeLatest(SAVE_FDES_FORM, postFdesResult),
    takeLatest(SAVE_FDES_FORM_GOOGLE, postFdesGoogleResult),
    takeLatest(SAVE_FDES_RESULT, saveFdesResult),
    takeLatest(UPDATE_CALCUL, patchCalcul),
    takeLatest(LOAD_REFERENCE, getReferences),
    takeLatest(GENERATE_FDES_XML, postFdesXml),
    takeEvery(GENERATE_REPORT, generateSingleReport),
];