import produce from "immer";
import {
    CHANGE_CALCUL_PAGE,
    CHANGE_CALCUL_SORT,
    DELETE_CALCUL,
    DELETE_CALCUL_FAILED,
    DELETE_CALCUL_SUCCESS,
    DUPLICATE_CALCUL_SUCCESS,
    GET_CALCUL_SUCCESS,
    GET_USER_CALCULS_FAILED,
    GET_USER_CALCULS_SUCCESS,
    LOAD_CALCUL_FAILED,
    LOAD_CALCUL_SUCCESS,
    LOAD_USER_CALCULS
} from "../constants";
import {SORT_CALCUL_MODIFIED_AT_DESC} from "../../../common/constants/sort";

const initialState = {
    calculs: [],
    calcul: {},
    isLoading: false,
    sort: SORT_CALCUL_MODIFIED_AT_DESC,
    calculLimit: 20,
    currentPage: 1,
    total: 0
};


function storeCalcul (draft, payload) {
    draft.calculs = [...draft.calculs.filter(x => x._id !== payload._id), payload]
}

const calculsReducer = produce((draft = initialState, action) => {
    switch (action.type) {
        case GET_CALCUL_SUCCESS:
            storeCalcul(draft, action.payload);
            break;
        case LOAD_CALCUL_SUCCESS:
            draft.calcul = action.payload;
            break;
        case LOAD_CALCUL_FAILED:
            draft.calcul = {};
            break;
        case GET_USER_CALCULS_SUCCESS:
            storeCalculs(draft, action.payload);
            break;
        case GET_USER_CALCULS_FAILED:
            draft.calculs = [];
            draft.total = 0;
            draft.isLoading = false;
            break;
        case CHANGE_CALCUL_SORT:
            draft.sort = action.sort;
            draft.isLoading = true;
            break;
        case CHANGE_CALCUL_PAGE:
            draft.isLoading = true;
            draft.currentPage = action.page;
            break;
        case LOAD_USER_CALCULS:
            draft.isLoading = true;
            break;
        case DELETE_CALCUL:
            draft.isLoading = true;
            break;
        case DELETE_CALCUL_SUCCESS:
            draft.isLoading = false;
            draft.calculs = draft.calculs.filter(calcul => calcul._id !== action.payload._id);
            break;
        case DELETE_CALCUL_FAILED:
            draft.isLoading = false;
            break;
        case DUPLICATE_CALCUL_SUCCESS:
            storeCalcul(draft, action.payload)
            break;
        default:
            return draft;
    }
});

function storeCalculs (draft, payload) {
    draft.calculs = payload.data;
    draft.total = payload.total;
    draft.isLoading = false;
}

export default calculsReducer;