import React from 'react';
import Breadcrumb from "../../../common/components/Breadcrumb";
import AccountSettingForm from "../containers/AccountSettingForm";
import PasswordForm from "../containers/PasswordForm";
import { withPermissions } from "../../../layout/Permissions";
import { PERMISSION_USER } from "../constants/permissions";

const UserSetting = () => {

    return (<>
        <Breadcrumb title="Paramètres du compte">
        </Breadcrumb>
        <div className="content-body">
            <section id="page-account-settings">
                <div className="row">
                    <div className="col-md-3 mb-2 mb-md-0">
                        <ul className="nav nav-pills flex-column mt-md-0 mt-1">
                            <li className="nav-item">
                                <a className="nav-link d-flex active" id="account-pill-general" data-toggle="pill"
                                    href="#account-vertical-general" aria-expanded="true">
                                    <i className="feather icon-globe" />
                                    Général
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link d-flex" id="account-pill-password" data-toggle="pill"
                                    href="#account-vertical-password" aria-expanded="false">
                                    <i className="feather icon-lock" />
                                    Sécurité
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-9">
                        <div className="card">
                            <div className="card-content">
                                <div className="card-body">
                                    <div className="tab-content">
                                        {//eslint-disable-next-line
                                            <div role="tabpanel" className="tab-pane active"
                                                id="account-vertical-general" aria-labelledby="account-pill-general"
                                                aria-expanded="true">
                                                <AccountSettingForm />
                                            </div>
                                        }
                                        {//eslint-disable-next-line
                                            <div className="tab-pane fade " id="account-vertical-password"
                                                role="tabpanel" aria-labelledby="account-pill-password"
                                                aria-expanded="false">
                                                <PasswordForm />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </>
    )

};

export default withPermissions(UserSetting, PERMISSION_USER);
