export const GET_CONFIGURATORS_SEND = 'GET_CONFIGURATORS_SEND';
export const GET_CONFIGURATORS_SUCCESS = 'GET_CONFIGURATORS_SUCCESS';
export const GET_CONFIGURATORS_FAILED = 'GET_CONFIGURATORS_FAILED';
export const CONFIGURATOR_FORM_SEND = 'CONFIGURATOR_FORM_SEND';
export const CONFIGURATOR_FORM_SUCCESS = 'CONFIGURATOR_FORM_SUCCESS';
export const CONFIGURATOR_FORM_FAILED = 'CONFIGURATOR_FORM_FAILED';
export const CONFIGURATOR_FORM_UPDATE = 'CONFIGURATOR_FORM_UPDATE';
export const CONFIGURATOR_FORM_UPDATE_SUCCESS = 'CONFIGURATOR_FORM_UPDATE_SUCCESS';
export const CONFIGURATOR_FORM_UPDATE_FAILED = 'CONFIGURATOR_FORM_UPDATE_FAILED';
export const CONFIGURATOR_FORM_DELETE = 'CONFIGURATOR_FORM_DELETE';
export const CONFIGURATOR_FORM_DELETE_SUCCESS = 'CONFIGURATOR_FORM_DELETE_SUCCESS';
export const CONFIGURATOR_FORM_DELETE_FAILED = 'CONFIGURATOR_FORM_DELETE_FAILED';
export const GET_LOCAL_CONFIGURATOR_SUCCESS = 'GET_LOCAL_CONFIGURATOR_SUCCESS';
export const GET_LOCAL_CONFIGURATOR_FAILED = 'GET_LOCAL_CONFIGURATOR_FAILED';