import {connect} from 'react-redux';
import ConfiguratorForm from '../components/ConfiguratorForm';
import {configuratorFormSend, configuratorFormUpdate} from "../actions";

export default connect(
    (state) => ({
        familyProducts: state.familyProduct.familyProducts,
        isLoading: state.configurator.isLoading
    }),
    (dispatch) => ({
        sendConfiguratorForm: (values) => {
            dispatch(configuratorFormSend(values))
        },
        configuratorFormUpdate: (values) => {
            dispatch(configuratorFormUpdate(values))
        }
    })

)(ConfiguratorForm);