import React, { useEffect, useState } from 'react';
import './Fdes.scss';
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import ReactTooltip from "react-tooltip";
import CollapsableCard from "../../../common/components/CollapsableCard";
import { NOMCALCUL, NOMPROJET } from '../constants/metadata';

const FdesInformations = ({ fdes, onChangeCalculName, calculName, projectName, onChangeProjectName, readOnly, metadata }) => {

    const [calcul, setCalcul] = useState({})
    const [project, setProject] = useState({})

    useEffect(() => {
        metadata && setCalcul(metadata.find(e => e.paramId === NOMCALCUL))
        metadata && setProject(metadata.find(e => e.paramId === NOMPROJET))
    }, [metadata])


    if (!fdes) {
        return '';
    }

    return (
        <CollapsableCard title="Votre calcul">
            <div className="row mb-2">
                {fdes.icon && (
                    <div className="col-3">
                        <img className="img-fluid bordered" src={fdes.icon}
                            alt={fdes.title} />
                    </div>
                )}
                <div className="col-9">
                    <div className="fdes-title">
                        <p className="font-weight-bold mb-0">{fdes.title}</p>
                        <p className="font-weight-bold font-italic font-small-2">(version {fdes.version} datée du {format(parseISO(fdes.createdAt), 'dd/MM/yyyy')})</p>
                    </div>
                    <div className="fdes-description">
                        <p>{fdes.description}</p>
                    </div>
                </div>
            </div>
            <div className="row mb-2">
                {fdes && fdes.documentation && fdes.documentation.fdesMere && fdes.documentation.fdesMere.file && fdes.documentation.fdesMere.file !== "" && (
                    <div className="col-3 font-size-xsmall">
                        <a href={fdes.documentation.fdesMere.file} target="_blank" rel="noopener noreferrer"><i className="feather icon-file" /> FDES mère</a>
                    </div>
                )}
                {fdes && fdes.documentation && fdes.documentation.guide && fdes.documentation.guide.file && fdes.documentation.guide.file !== "" && (
                    <div className="col-4 font-size-xsmall">
                        <a href={fdes.documentation.guide.file} target="_blank" rel="noopener noreferrer"><i className="feather icon-file" /> Guide utilisateur</a>
                    </div>
                )}
                {fdes && fdes.methodologyNote && fdes.methodologyNote !== "" && (
                    <div className="col-5 font-size-xsmall">
                        <a href={fdes.methodologyNote} target="_blank" rel="noopener noreferrer"><i className="feather icon-file" /> Note méthodologique</a>
                    </div>
                )}
            </div>
            <div className="row">
                <div className="col-12">
                    <fieldset className="form-group">
                        <label htmlFor="basicInput">{calcul && calcul.name ? calcul.name : 'Nom de votre calcul (apparait sur la FDES)'}<i
                            className="feather icon-info ml-3"
                            data-tip={calcul && calcul.helpText ? calcul.helpText : 'Le "Nom de votre calcul" est utilisé comme titre de la FDES (apparait sur la couverture de votre FDES en PDF) et comme nom du calcul dans la liste des calculs de la section "Mes calculs".'}
                            data-for="calculName"
                        /></label>
                        <input
                            type="text"
                            value={calculName}
                            onChange={(e) => onChangeCalculName(e.target.value)}
                            className="form-control"
                            id="basicInput"
                            disabled={readOnly}
                        />
                        <ReactTooltip
                            id="calculName"
                            place="right"
                            type="info"
                            className="basic-tooltip"
                        />
                    </fieldset>
                    <fieldset className="form-group">
                        <label htmlFor="basicInput">{project && project.name ? project.name : 'Nom de votre projet'}<i
                            className="feather icon-info ml-3"
                            data-tip={project && project.helpText ? project.helpText : `Le "Nom de votre projet" n'est utile que pour vous, il s'agit d'une référence interne qui vous permet de trier la liste de vos calculs ou d'associer ce calcul avec d'autres au sein d'un projet... Ce champ n'apparait pas sur votre FDES, et vous pouvez le laisser vide.`}
                            data-for="projectName"
                        /></label>
                        <input
                            type="text"
                            value={projectName}
                            onChange={(e) => onChangeProjectName(e.target.value)}
                            className="form-control"
                            id="projectName"
                            disabled={readOnly}
                        />
                        <ReactTooltip
                            id="projectName"
                            place="right"
                            type="info"
                            className="basic-tooltip"
                        />
                    </fieldset>
                </div>
            </div>
        </CollapsableCard>
    )
};

export default FdesInformations;
