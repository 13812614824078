import React from 'react';
import ReactTooltip from "react-tooltip";
import CollapsableCard from "../../../common/components/CollapsableCard";
import {DEFAULT_ACTIVE_COLOR} from "../../../common/constants/color";
import NumericContainer from "./NumericContainer";
import FdesFormSelect from "./FdesFormSelect";
import PercentContainer from './PercentContainer';

const FdesFormGroup = ({fdesParameterGroups, onChangeFdesParameterForm, fields, isAuto, readOnly, localConfigurator, uploadFile}) => {
    const activeColor = localConfigurator && localConfigurator.theme && localConfigurator.theme.colors && localConfigurator.theme.colors.primaryText ? localConfigurator.theme.colors.primaryText : DEFAULT_ACTIVE_COLOR;

    return (
        (fdesParameterGroups && fdesParameterGroups.length > 0 && (
            fdesParameterGroups.map(parameterGroup =>
                parameterGroup.parameters.filter(parameter => ((!parameter.visible && parameter.visible !== false) || parameter.visible === true)).length > 0 && (
                    <CollapsableCard title={parameterGroup.name} key={parameterGroup.name} classes="margin-scroll" defaultOpen={parameterGroup.parameters[0].groupIsOpen}>
                        {parameterGroup && parameterGroup.parameters && parameterGroup.parameters.length > 0 && (
                            parameterGroup.parameters.map(parameter =>
                                ((!parameter.visible && parameter.visible !== false) || parameter.visible === true) && (
                                    <fieldset className="form-group" key={parameter.name}>
                                        <label htmlFor="basicInput">{parameter.name}
                                            {parameter.helpText && parameter.helpText !== "" && (<i
                                                className="feather icon-info ml-3" data-tip={parameter.helpText}
                                                data-for={parameter._id} />
                                            )}
                                        </label>
                                        {parameter.type === "select" && (
                                            <FdesFormSelect
                                                activeColor={activeColor}
                                                fields={fields}
                                                parameter={parameter}
                                                onChangeFdesParameterForm={onChangeFdesParameterForm}
                                                isAuto={isAuto}
                                                readOnly={readOnly}
                                            />
                                        )}
                                        {parameter.type === "button" && (
                                            <div className="button-container">
                                                <div className="btn-group" role="group" >
                                                    {parameter.values && (parameter.values.map((label, value) =>
                                                        <button
                                                            key={value}
                                                            type="button"
                                                            disabled={readOnly}
                                                            className={`btn ${fields && fields.find(x => x.paramId === parameter.paramId) &&
                                                                fields.find(x => x.paramId === parameter.paramId).value &&
                                                                fields.find(x => x.paramId === parameter.paramId).value.label === label
                                                                ? 'btn-primary' : 'btn-outline-primary'}`}
                                                            onClick={() => onChangeFdesParameterForm(parameter.paramId, {value: label, label: label}, (isAuto === 'Y' && !parameter.isGeneralParameter))}>
                                                            {label}
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {(parameter.type === "numeric" || parameter.type === "numericslider") && (
                                            <NumericContainer
                                                type={parameter.type}
                                                readOnly={readOnly}
                                                validationRules={parameter?.validationRules}
                                                defaultValue={fields && fields.find(x => x.paramId === parameter.paramId) && (fields.find(x => x.paramId === parameter.paramId).value.value) ? fields.find(x => x.paramId === parameter.paramId).value.value : 0}
                                                onChange={(value) => onChangeFdesParameterForm(parameter.paramId, {value, label: value}, (isAuto === 'Y' && !parameter.isGeneralParameter))}
                                            />
                                        )}
                                        {(parameter.type === "percent" || parameter.type === "percentslider") && (
                                            <PercentContainer
                                                type={parameter.type}
                                                readOnly={readOnly}
                                                validationRules={parameter?.validationRules}
                                                defaultValue={fields && fields.find(x => x.paramId === parameter.paramId) && (fields.find(x => x.paramId === parameter.paramId).value.value) ? fields.find(x => x.paramId === parameter.paramId).value.value : 0}
                                                onChange={(value) => onChangeFdesParameterForm(parameter.paramId, {value: ((parseFloat(value) / 100)).toString().replace('.', ','), label: ((parseFloat(value) / 100)).toString().replace('.', ',')}, (isAuto === 'Y' && !parameter.isGeneralParameter))}
                                            />
                                        )}
                                        {(parameter.type === "string" || parameter.type === "integer" || parameter.type === "decimal") && (
                                            <input
                                                type={parameter.type === "integer" ? "number" : "text"}
                                                value={fields && fields.find(x => x.paramId === parameter.paramId) && (fields.find(x => x.paramId === parameter.paramId).value.value)}
                                                onChange={(e) => onChangeFdesParameterForm(parameter.paramId, {
                                                    value: e.target.value,
                                                    label: e.target.value
                                                }, (isAuto === 'Y' && !parameter.isGeneralParameter))}
                                                className="form-control"
                                                id="basicInput"
                                                disabled={readOnly}
                                            />
                                        )}
                                        {parameter.type === 'text' &&
                                            <>
                                                {parameter.validationRules && parameter.validationRules.max > 70 ?
                                                    <textarea
                                                        type={parameter.type}
                                                        value={fields && fields.find(x => x.paramId === parameter.paramId) && (fields.find(x => x.paramId === parameter.paramId).value.value)}
                                                        onChange={(e) => onChangeFdesParameterForm(parameter.paramId, {
                                                            value: e.target.value,
                                                            label: e.target.value
                                                        }, (isAuto === 'Y' && !parameter.isGeneralParameter))}
                                                        className="form-control"
                                                        disabled={readOnly}
                                                        maxLength={parameter.validationRules.max ? parameter.validationRules.max : 100}
                                                    /> : <input
                                                        type={parameter.type}
                                                        value={fields && fields.find(x => x.paramId === parameter.paramId) && (fields.find(x => x.paramId === parameter.paramId).value.value)}
                                                        onChange={(e) => onChangeFdesParameterForm(parameter.paramId, {
                                                            value: e.target.value,
                                                            label: e.target.value
                                                        }, (isAuto === 'Y' && !parameter.isGeneralParameter))}
                                                        className="form-control"
                                                        disabled={readOnly}
                                                        maxLength={parameter.validationRules.max ? parameter.validationRules.max : 100}
                                                    />
                                                }
                                            </>
                                        }
                                        {parameter.type === 'image' &&
                                            <div className=''>
                                                <input
                                                    type={'file'}
                                                    accept="image/png, image/jpeg, image/jpg"
                                                    onChange={(e) => {
                                                        uploadFile(parameter.paramId, e.target.files[0], (isAuto === 'Y' && !parameter.isGeneralParameter));
                                                    }}
                                                    className="form-control"
                                                    disabled={readOnly}
                                                />
                                                {fields && fields.find(x => x.paramId === parameter.paramId) &&
                                                    (
                                                        <div className="max-image">Image actuelle : <img className="img-fluid" alt="Logo" src={(fields.find(x => x.paramId === parameter.paramId).value.value)} /></div>
                                                    )}
                                            </div>
                                        }
                                        {parameter.helpText && parameter.helpText !== "" && (<ReactTooltip
                                            id={parameter._id}
                                            place="right"
                                            type="info"
                                            className="basic-tooltip"
                                        />)}
                                    </fieldset>
                                )
                            )
                        )}
                    </CollapsableCard>
                )
            )
        ))
    )
};

export default FdesFormGroup;