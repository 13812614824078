import {
    LOAD_FDES_PARAMETERS,
    CHANGE_FDES_PARAMETER_FORM,
    SAVE_FDES_FORM,
    SAVE_FDES_FORM_GOOGLE,
    SAVE_CURRENT_FDES_ID,
    LOAD_REFERENCE,
    CHANGE_SYNTHETIC_RESULT_FORM,
    CHANGE_MODULE_RESULT_FORM,
    CHANGE_CALCUL_NAME_FORM,
    SAVE_FDES_RESULT,
    GENERATE_FDES_XML,
    CHANGE_PROJECT_NAME_FORM,
    LOAD_CALCUL_DATA,
    UPDATE_CALCUL,
    SET_LAST_SAVED_USER_FDES_ID,
    CLEAN_NAME_FORM,
    UPGRADE_CALCUL,
    GET_FDES_METADATA,
    POST_FDES_FILE,
    GET_FDES_METADATA_CALCUL_RAW,
} from "../constants";

export function loadFdesParameters (fdesId, isRealTime, calcul) {
    return {
        type: LOAD_FDES_PARAMETERS, fdesId, isRealTime, calcul
    };
}

export function changeFdesParameterForm (paramId, value) {
    return {
        type: CHANGE_FDES_PARAMETER_FORM, paramId, value
    };
}

export function saveFdesForm () {
    return {
        type: SAVE_FDES_FORM
    };
}

export function postFdesFile (paramId, file) {
    return {
        type: POST_FDES_FILE, paramId, file
    };
}

export function saveFdesGoogleForm () {
    return {
        type: SAVE_FDES_FORM_GOOGLE
    };
}

export function saveFdesResult () {
    return {
        type: SAVE_FDES_RESULT
    };
}

export function saveCurrentFdesId (fdesId) {
    return {
        type: SAVE_CURRENT_FDES_ID, fdesId
    };
}

export function getFdesMetaData (fdesId) {
    return {
        type: GET_FDES_METADATA, fdesId
    };
}

export function getFdesMetaDataForCalculRaw (fdesId, resolve, reject) {
    return {
        type: GET_FDES_METADATA_CALCUL_RAW, fdesId, resolve, reject
    };
}

export function loadReference (standard) {
    return {
        type: LOAD_REFERENCE, standard
    };
}

export function changeSyntheticResultForm (field, value) {
    return {
        type: CHANGE_SYNTHETIC_RESULT_FORM, field, value
    };
}

export function changeModuleResultForm (value) {
    return {
        type: CHANGE_MODULE_RESULT_FORM, value
    };
}

export function changeCalculNameForm (value) {
    return {
        type: CHANGE_CALCUL_NAME_FORM, value
    };
}

export function changeProjectName (value) {
    return {
        type: CHANGE_PROJECT_NAME_FORM, value
    };
}

export function generateFdesXml (familyProductId) {
    return {
        type: GENERATE_FDES_XML, familyProductId
    };
}

export function loadCalculData (calcul) {
    return {
        type: LOAD_CALCUL_DATA, calcul
    };
}

export function updateCalcul (calculId) {
    return {
        type: UPDATE_CALCUL, calculId
    };
}
export function setLastSavedUserFdesId (calculId) {
    return {
        type: SET_LAST_SAVED_USER_FDES_ID, calculId
    };
}

export function cleanNameForm () {
    return {
        type: CLEAN_NAME_FORM
    };
}

export function upgradeCalcul (calculId) {
    return {
        type: UPGRADE_CALCUL, calculId
    };
}