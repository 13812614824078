import React, {useEffect, useState} from 'react';
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import isBefore from "date-fns/isBefore";
import Button from "react-bootstrap/Button";
import './CalculRaw.scss';
import ReactTooltip from "react-tooltip";
import {Link} from "react-router-dom";
import DeleteModal from "../../../common/components/DeleteModal";
import ExposantValue from "../../fdes/components/ExposantValue";
import DuplicateModal from '../../../common/components/DuplicateModal';
import {DOWNLOADXMLCONFIG} from '../../fdes/constants/metadata';

const CalculRaw = ({calcul, calculIdXmlReport,
    calculIdPdfReport,
    xmlReportIsLoading,
    pdfReportIsLoading, generateXml, generatePdf, deleteCalcul, duplicateCalcul, aggregatedFamilyProducts}) => {
    const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
    const [duplicateModalIsOpen, setDuplicateModalIsOpen] = useState(false);
    const [downloadXmlConfig, setDownloadXmlConfig] = useState({})

    useEffect(() => {
        calcul && calcul.fdesMetadata && setDownloadXmlConfig(calcul.fdesMetadata.find(e => e.paramId === DOWNLOADXMLCONFIG))
    }, [calcul])

    function downloadLink (link) {
        window.open(link);
    }

    const isLastModel = calcul && calcul.model && calcul.lastModel && calcul.lastModel._id === calcul.model._id;
    const calculHasExports = calcul && calcul.reports;
    const isModelCompatible = calcul && calcul.isCompatible === true;

    function getExportTooltip (type) {

        if (calcul.reports && calcul.reports[type]) {
            return `Télécharger l'export ${type.toUpperCase()}`;
        } else if (isLastModel) {
            return `Générer ma FDES`;
        } else if (isModelCompatible) {
            return `Ce calcul a été créé avec une version précédente du configurateur de la famille de produits “${calcul.productFamily.name}”. Par chance cette version précédente et la nouvelle version sont compatibles entre elles ! Ainsi vous pouvez 1/ maintenir ce calcul dans sa version originale, ce qui vous permettra uniquement de visualiser ses résultats, 2/ convertir ce calcul selon la nouvelle version, ce qui vous permettra de l'éditer totalement`
        } else {
            return `Ce calcul a été créé avec une version précédente du configurateur de la famille de produits “${calcul.productFamily.name}”. Malheureusement cette version précédente et la nouvelle version sont incompatibles entre elles. Ainsi vous ne pouvez plus éditer ce calcul et vous ne pouvez plus générer de FDES, mais seulement visualiser vos paramètres de calcul et les résultats associés.`
        }
    }

    function handleClickIcon (type) {
        if (calcul.reports && calcul.reports[type]) {
            downloadLink(calcul.reports[type]);
        } else if (isLastModel) {
            generatePdf(calcul._id);
            generateXml(calcul._id);
        }
    }

    async function onSubmitDuplicateCalcul () {
        duplicateCalcul(calcul._id);
        // window.location.reload();
    }

    function displayDowloadXmlConfig () {
        if (downloadXmlConfig)
            return downloadXmlConfig.visible
        else
            return true
    }

    return (
        <>
            <DeleteModal
                isOpen={deleteModalIsOpen}
                onClose={setDeleteModalIsOpen}
                onSubmit={() => deleteCalcul(calcul._id)}
            />
            <DuplicateModal
                isOpen={duplicateModalIsOpen}
                onClose={setDuplicateModalIsOpen}
                onSubmit={() => {onSubmitDuplicateCalcul()}}
            />
            <tr className="calcul-raw align-items-center">
                <td className="py-1 px-1">
                    {calcul && calcul.reports && calcul.reports.createdAt && calcul.model && calcul.model.reportReferenceUpdatedAt && isBefore(parseISO(calcul.reports.createdAt), parseISO(calcul.model.reportReferenceUpdatedAt)) && (
                        <>
                            <i className="feather icon-alert-triangle warning lighten-2 mr-1"
                                data-tip="Une mise à jour des modèles d’export a été effectuée, cette mise a jour est sans impact sur les résultats de votre calcul."
                                data-for={`alert${calcul._id}`}
                            />
                            <ReactTooltip
                                id={`alert${calcul._id}`}
                                place="right"
                                type="info"
                                className="basic-tooltip"
                            />
                        </>
                    )}
                    {calcul && calcul.model && !calcul.model.active && (
                        <>
                            {calcul?.isCompatible ?
                                <i className="feather icon-alert-triangle danger darken-4 mr-1"
                                    data-tip="Une mise à jour du modèles de calcul a été effectuée, cette mise à jour a un impact sur les résultats de votre calcul. Vous ne pouvez plus modifier ce calcul. Si vous souhaitez recalculer les résultats de votre calcul avec la nouvelle version de calcul, veuillez créer un nouveau calcul."
                                    data-for={`maintenance${calcul._id}`}
                                />
                                :
                                <i className="feather icon-alert-triangle danger darken-4 mr-1"
                                    data-tip="Calcul non duplicable réalisé avec un ancien modèle de calcul non compatible avec l’actuel."
                                    data-for={`maintenance${calcul._id}`}
                                />
                            }
                            <ReactTooltip
                                id={`maintenance${calcul._id}`}
                                place="right"
                                type="info"
                                className="basic-tooltip"
                            />
                        </>
                    )}
                    {calcul.title}
                </td>
                <td className="py-1 px-1">{calcul.project}</td>
                <td className="py-1 px-1">{calcul.model && calcul.model.productFamily && (calcul.model.productFamily.label || calcul.model.productFamily.name) ? calcul.model.productFamily.label || calcul.model.productFamily.name : ''}</td>
                <td className="py-1 px-1">{calcul.model && calcul.model.version ? calcul.model.version : ''}</td>
                <td className="py-1 px-1">{format(parseISO(calcul.createdAt), 'dd/MM/yyyy HH:mm')}</td>
                <td className="py-1 px-1">{format(parseISO(calcul.updatedAt), 'dd/MM/yyyy HH:mm')}</td>
                <td className="py-1 px-1">{calcul.metrics && (calcul.metrics.referenceValue || calcul.metrics.totalGlobalWarming) ? (calcul.metrics.referenceValue ? <ExposantValue value={calcul.metrics.referenceValue} unit={calcul.metrics.referenceUnit} forceLiteral /> : <ExposantValue value={calcul.metrics.totalGlobalWarming} unit='kg eq. CO2' forceLiteral />) : '0'}</td>
                <td className="py-1 px-1">
                    <div className="d-block text-center p-0 button-container">
                        {pdfReportIsLoading && calculIdPdfReport === calcul._id ?
                            <div className="spinner-border  mr-1 float-right " role="status" /> :
                            <>
                                <span
                                    className={displayDowloadXmlConfig() ? `mr-1 ${calcul.reports && calcul.reports.pdf ? "" : "disabled"}` : `${calcul.reports && calcul.reports.pdf ? "" : "disabled"}`}
                                    data-tip={getExportTooltip('pdf')}
                                    data-for={`exportPdf${calcul._id}`}
                                    onClick={() => handleClickIcon('pdf')}
                                ><i className="fa fa-file-pdf-o" /></span>
                                <ReactTooltip
                                    id={`exportPdf${calcul._id}`}
                                    place="left"
                                    type="info"
                                    className="basic-tooltip"
                                />
                            </>
                        }
                        {displayDowloadXmlConfig() && (xmlReportIsLoading && calculIdXmlReport === calcul._id ?
                            <div className="spinner-border  mr-1 float-right " role="status" /> :
                            <>
                                <span
                                    className={calcul.reports && calcul.reports.xml ? "" : "disabled"}
                                    data-tip={getExportTooltip('xml')}
                                    data-for={`exportXml${calcul._id}`}
                                    onClick={() => handleClickIcon('xml')}
                                ><i className="fa fa-file-code-o" /></span>
                                <ReactTooltip
                                    id={`exportXml${calcul._id}`}
                                    place="left"
                                    type="info"
                                    className="basic-tooltip"
                                />
                            </>
                        )}
                    </div>
                </td>
                <td className="py-1 px-1">
                    <div className="col p-0 text-center">
                        {calculHasExports ? (
                            <>
                                <div className="text-center">
                                    <Link className="white"
                                        to={`/fdes/${calcul.productFamily.id}/${calcul._id}`}>
                                        <Button variant="info" data-tip={"Consulter"} data-for={'Consulter'}><i
                                            className="feather icon-eye" />
                                        </Button>
                                    </Link>
                                </div>
                                <div className="text-center mt-1">
                                    {calcul?.isCompatible && aggregatedFamilyProducts.find(x => x.id === calcul.productFamily.id)?.isAccessible &&
                                        <Button className="" variant="secondary" onClick={() => setDuplicateModalIsOpen(true)} data-tip={"Dupliquer"} data-for={'Dupliquer'}><i className="feather icon-copy" /></Button>
                                    }
                                </div>
                            </>
                        ) : isLastModel ?
                            (
                                <>
                                    <div className="text-center">
                                        <Link className="white" to={`/fdes/${calcul.productFamily.id}/${calcul._id}`}><Button className='' variant="success" data-tip={"Modifier"} data-for={'Modifier'}><i className="feather icon-edit-1" /></Button></Link>

                                    </div>
                                    <div className="text-center mt-1">
                                        <Button className="" variant="danger" onClick={() => setDeleteModalIsOpen(true)} data-tip={"Supprimer"} data-for={'Supprimer'}><i className="feather icon-trash-2" /></Button>
                                    </div>
                                    <div className="text-center mt-1">
                                        {calcul?.isCompatible && aggregatedFamilyProducts.find(x => x.id === calcul.productFamily.id)?.isAccessible &&
                                            <Button className="" variant="secondary" onClick={() => setDuplicateModalIsOpen(true)} data-tip={"Dupliquer"} data-for={'Dupliquer'}><i className="feather icon-copy" /></Button>
                                        }
                                    </div>
                                </>
                            ) :
                            (
                                <>
                                    <div className="text-center">
                                        <Link className="white"
                                            to={`/fdes/${calcul.productFamily.id}/${calcul._id}`}>
                                            <Button variant="warning" className="" data-tip={"Consulter"} data-for={'Consulter'}><i
                                                className="feather icon-eye" /></Button>
                                        </Link>
                                    </div>
                                    <div className="text-center mt-1">
                                        <Button variant="danger" onClick={() => setDeleteModalIsOpen(true)} data-tip={"Supprimer"} data-for={'Supprimer'}><i className="feather icon-trash-2" /></Button>
                                    </div>
                                </>
                            )
                        }
                        <ReactTooltip
                            id='Modifier'
                            place="top"
                            type="info"
                            className="basic-tooltip"
                        />
                        <ReactTooltip
                            id='Consulter'
                            place="top"
                            type="info"
                            className="basic-tooltip"
                        />
                        <ReactTooltip
                            id='Supprimer'
                            place="top"
                            type="info"
                            className="basic-tooltip"
                        />
                        <ReactTooltip
                            id='Dupliquer'
                            place="top"
                            type="info"
                            className="basic-tooltip"
                        />
                    </div>
                </td>
            </tr>
        </>
    )

};

export default CalculRaw;
