import {connect} from 'react-redux';
import Calculs from "../components/Calculs";
import {changeCalculPage, changeCalculSort, loadUserCalculs, generateReport, deleteCalcul, duplicateCalcul, getCalcul} from "../actions";
import {getFdesMetaDataForCalculRaw} from '../../fdes/actions';

export default connect(
    (state) => ({
        calculs: state.calculs.calculs,
        isLoading: state.calculs.isLoading,
        sort: state.calculs.sort,
        currentPage: state.calculs.currentPage,
        limit: state.calculs.calculLimit,
        total: state.calculs.total,
        calculIdXmlReport: state.loader.calculIdXmlReport,
        calculIdPdfReport: state.loader.calculIdPdfReport,
        xmlReportIsLoading: state.loader.xmlReportIsLoading,
        pdfReportIsLoading: state.loader.pdfReportIsLoading,
        aggregatedFamilyProducts: state.familyProduct.aggregatedFamilyProducts
    }),
    (dispatch) => ({
        loadUserCalculs: () => {
            dispatch(loadUserCalculs())
        },
        getCalcul: (calculId) => {
            dispatch(getCalcul(calculId))
        },
        changeCalculSort: (sort) => {
            dispatch(changeCalculSort(sort))
        },
        onChangePage: (page) => {
            dispatch(changeCalculPage(page))
        },
        generatePdf: (userFdesId) => {
            dispatch(generateReport(userFdesId, "pdf"))
        },
        generateXml: (userFdesId) => {
            dispatch(generateReport(userFdesId, "xml"))
        },
        deleteCalcul: (calculId) => {
            dispatch(deleteCalcul(calculId))
        },
        duplicateCalcul: (calculId) => {
            dispatch(duplicateCalcul(calculId))
        },
        getFdesMetadataCalculRaw: (fdesId) => {
            return new Promise((resolve, reject) => {
                dispatch(getFdesMetaDataForCalculRaw(fdesId, resolve, reject));
            })
        }
    })

)(Calculs);